<template>
<div>
    <v-container class="faq_box">

        <h1 class="text-center mb-10 page_title">よくある質問</h1>

        <v-card class="my-6 py-3 px-4 faq_card" v-for="faq in faqs" :key="faq.id">
            <v-icon class="f-20 ml-1 float-left">fas fa-question-circle</v-icon>
             <div class="float-left text_box">
           <p class="pl-5 f-14 px-0 my-0"  style="white-space: pre-wrap;">{{faq.question}}</p>
             </div>
              <div class="clear"></div>
           <hr class="my-6"/>
           <v-icon class="f-red f-20 ml-2 float-left">fas fa-font</v-icon>
           <div class="float-left text_box">
           <p class="f-12 pl-5"  style="white-space: pre-wrap;">{{faq.answer}}</p>
           </div>
           <div class="clear"></div>
        </v-card>


    </v-container>
</div>
</template>

<script>
import axios from "axios"

export default {
    data() {
        return {
            faqs: 0
        }
    },

    async mounted() {

        const response = await axios.post('/master/faq')
        this.faqs = response.data.faqs

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/faq.scss";
</style>
